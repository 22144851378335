import * as React from 'react'
import PropTypes from 'prop-types'
import * as CustomTypes from 'data/types'
import { graphql } from 'gatsby'

import ThemeWrapper from 'Theme'
import Layout from 'layout/BaseLayout'
import Seo from 'components/seo'

import { ModalProvider } from 'context'
import ContentBlocks, { contentBlockTypes } from 'ContentBlocks'

export const query = graphql`
  query PageQuery($pageId: String) {
    wpPage(id: { eq: $pageId }) {
      id
      slug
      title
      PageSettings {
        shortFooter
        fieldGroupName
      }
      blocks {
        ...contentBlocksQuery
      }
      seo {
        ...seoQuery
      }
    }
    wp {
      generalSettings {
        title
      }
      themeSettingsPage {
        themeSettings {
          socialLinks {
            socialLink {
              url
              title
              target
            }
            socialType
          }
        }
      }
    }
  }
`
const PageTemplate = ({ data, location }) => {
  const {
    wpPage: { blocks, title, seo, PageSettings },
    wp: { generalSettings, themeSettingsPage },
  } = data

  return (
    <ThemeWrapper>
      <ModalProvider>
        <Layout
          generalSettingsTitle={generalSettings.title}
          isFooterShort={PageSettings.shortFooter}
          title={title}
          socialLinksData={themeSettingsPage.themeSettings.socialLinks}
        >
          <Seo title={title} seo={seo} />
          <ContentBlocks
            socialLinksData={themeSettingsPage.themeSettings.socialLinks}
            blocksData={blocks}
            pageLocation={location}
          />
        </Layout>
      </ModalProvider>
    </ThemeWrapper>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.shape({
    wpPage: PropTypes.shape({
      blocks: contentBlockTypes.blocksListType,
      title: PropTypes.string,
      seo: PropTypes.shape({}),
      PageSettings: PropTypes.shape({
        shortFooter: PropTypes.bool,
      }),
    }),
    wp: PropTypes.shape({
      generalSettings: {
        title: PropTypes.string,
      },
      themeSettingsPage: PropTypes.shape({
        themeSettings: PropTypes.shape({
          socialLinks: PropTypes.arrayOf,
        }),
      }),
    }),
  }).isRequired,
  location: CustomTypes.pageLocationType.isRequired,
}

export default PageTemplate
